//
// GLOBAL STYLES
//
body { font-family: $tucker-interface; }
h1, h2, h3, h4, h5, h6 {
  font-family: $tucker-headings;
  font-weight: 700;
}
h1 { font-size: 3.25rem; }
h2 { font-size: 2.75rem; }
h3 { font-size: 2.5rem; }
h4 { font-size: 2.25rem; }
h5 { font-size: 2rem; }
h6 { font-size: 1.5rem; }
p {
  font-family: $tucker-body;
  margin-bottom: 0;
}

blockquote {
  font-style: italic;
  color: $gray-light;
  font-size: 1rem;
  padding-left: 1.5rem;
  margin-right: 2rem;
  border-left: .375rem solid $gray-lighter;
}

img {
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
  position: relative;
  figcaption { @extend .tucker-caption; }
}

div.wrap { margin-top: 2rem; }



//
// UTILITIES
//
@mixin nice_spacing {
  // Text
  & > * + * {
    margin-top: 1.5rem;
  }
  & > * + h1 {
    margin-top: 4rem;
  }
  & > * + h2 {
    margin-top: 3rem;
  }
  & > * + h3 {
    margin-top: 2.5rem;
  }
  & > * + h4 {
    margin-top: 2.25rem;
  }
  & > * + h5 {
    margin-top: 2rem;
  }
  & > * + h6 {
    margin-top: 2rem;
  }
  // Images and embeds
  & > img,
  & > div.carousel,
  & > figure,
  & > div.embed-container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  & > img + img,
  & > figure + figure,
  & > img + figure,
  & > figure + img {
    margin-top: 1rem;
  }
  // Blocks
  & > article + article {
    margin-top: 3rem;
  }
}

.tucker-button {
  padding-top: .25rem !important;
  padding-right: 1.25rem !important;
  padding-bottom: .25rem !important;
  padding-left: 1.25rem !important;
  border-radius: .5rem;
  font-weight: 700;
  border: 2px solid;
  text-transform: uppercase;
  transition: $btn-transition;
  a {
    display: block;
    white-space: normal;
    padding: .5em 0;
    letter-spacing: .4px;
    transition: $btn-transition;
    &:hover, &:active { border-bottom: none !important; }
  }
}
@mixin button_outline($fg, $bg) {
  border-color: $fg;
  background-color: $bg;
  a { color: $fg; }
  &:hover,
  &:active {
    border-color: darken($fg, 10%);
    a { color: darken($fg, 10%); }
  }
}
@mixin button_fill($fg, $bg) { // Note: fill buttons must have a $bg color specified, cannot be transparent
  border-color: $fg;
  background-color: $fg;
  a { color: $bg; }
  &:hover,
  &:active {
    border-color: darken($fg, 10%);
    background-color: darken($fg, 10%);
    a { color: $bg; }
  }
}

.tucker-caption {
  position: absolute;
  bottom: 0;
  padding: .25rem .5rem;
  width: 100%;
  font-family: $tucker-body;
  font-size: .875rem !important;
  color: $gray-lighter !important;
  background-color: rgba(0, 0, 0, 0.25);
}
