.siemaGallery,
#siemaHome {
  position: relative;
  width: 100%;
  &::after {
    padding-top: 65%;
    display: block;
    content: '';
  }
  & > div {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
  }
  & > p {
    font-family: "FontAwesome" !important;
    font-size: 3rem;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    height: 100%;
    width: 3rem;
  }
  p.prevArrow {
    left: 0;
    // background: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(120, 120, 120, 0.4));
  }
  p.nextArrow {
    right: 0;
    // background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(120, 120, 120, 0.4));
  }
  .gallery-item {
    position: relative;
    figcaption {
      @extend .tucker-caption;
    }
  }
}

#siemaHome {
  &::after {
    padding-top: 50%;
    min-height: 12rem;
  }
  .slide {
    position: relative;
    figcaption {
      position: absolute;
      bottom: 0;
      padding: 4vw 6vw;
      @include media-breakpoint-down(sm) { padding: 1rem 3rem; }
      width: 100%;
      background-color: transparent;
      h1 {
        font-family: $tucker-headings;
        font-size: 4rem;
        @include media-breakpoint-only(sm) { font-size: 3.25rem; }
        @include media-breakpoint-down(xs) { font-size: 2.5rem; }
        text-shadow: 0 5px 5px rgba(0,0,0,.1),
                     8px 5px 5px rgba(0,0,0,.1),
                     -8px 5px 5px rgba(0,0,0,.1);
        color: $white;
        a {
          color: inherit;
          &:hover, &:active, &:focus { border-bottom: 4px solid; }
        }
      }
      p {
        font-family: $tucker-body;
        font-size: 1rem;
        margin-top: 1rem;
        color: $gray-lightest;
        text-shadow: 0 -2px 5px rgba(0,0,0,.25),
                     4px 2px 5px rgba(0,0,0,.25),
                     -4px 2px 5px rgba(0,0,0,.25);
        @include media-breakpoint-down(sm) { display: none; }
      }
      background-color: transparent;
    }
  }
}
