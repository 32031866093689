footer#logoArea { // Sponsor & partner logo footer
  padding-top: 2rem;
  padding-bottom: 2rem;
  .row {
    align-items: center;
    justify-content: center;
  }
  p {
    font-family: $tucker-body;
    color: $gray-light;
    text-align: center;
    margin-bottom: .5rem;
  }
  div.logo {
    padding: 2rem;
    text-align: center;
    &:not(.wide) {
      @include media-breakpoint-only(xs) {
        max-width: 75vw;
      }
    }
  }
}

footer.content-info { // Main site footer
  color: $gray-lighter;
  background-color: $footer-color;
  .container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  p { margin-bottom: 1rem; }
  ul { color: $tucker-green; }
  a {
    color: $gray-lighter;
    &:hover { border-bottom: 2px solid; }
  }
  .widget_social-icons {
    a {
      border: 2px solid $gray-lighter;
      &:hover {
        background-color: $gray-lighter;
        .fa { color: $footer-color; }
      }
    }
    .fa { color: $gray-lighter; }
  }
  .custom-button {
    @include button_fill($gray-lighter, $footer-color);
    &.outline { @include button_outline($gray-lighter, transparent); }
    a { border-bottom: none; }
  }
}


div.colophon { // Colophon (the very very bottom)
  background-color: #212222;
  .container {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .row {
    align-items: center;
    justify-content: space-between;
  }
  p {
    color: $gray-light;
    font-family: $tucker-interface;
    font-size: .75rem;
    margin: 0;
  }
  a {
    color: $tucker-green;
    &:hover { border-bottom: 2px solid; }
  }
}
