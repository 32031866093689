body#tinymce {
  margin: 1rem !important;
  @include nice_spacing;
  a:hover,
  a:active {
    color: $tucker-green;
    border-bottom: 2px solid;
  }
  ul { font-family: $tucker-body; }
  blockquote:first-child {
    font-style: normal;
    color: inherit;
    font-size: inherit;
    padding-left: 0;
    margin-right: 0;
    border-left: none;
    p {
      font-size: 1.25rem;
    }
  }

  .wp-caption { position: relative; }
  .wp-caption .wp-caption-dt img { margin-bottom: 0; }
  .wp-caption-dd { @extend .tucker-caption; }
}
