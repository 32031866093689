.main {
  @include nice_spacing;
  a:hover,
  a:active {
    color: $tucker-green;
    border-bottom: 2px solid;
  }
  ul { font-family: $tucker-body; }
  margin-bottom: 2rem;
  blockquote:first-child {
    font-style: normal;
    color: inherit;
    font-size: inherit;
    padding-left: 0;
    margin-right: 0;
    border-left: none;
    p {
      font-size: 1.25rem;
    }
  }
  .custom-button {
    & > a { color: $white !important; }
    &.outline {
      & > a { color: $tucker-green !important; }
      &:hover, &:active {
        & > a { color: darken($tucker-green, 10%) !important; }
      }
    }
    &.outline.red {
      & > a { color: $tucker-red !important; }
      &:hover, &:active {
        & > a { color: darken($tucker-red, 10%) !important; }
      }
    }
    &.block {
      width: 100%;
    }
  }
}

body.home {
  div.wrap {
    @extend .container-fluid;
    margin-top: 0;
    background-image: url('../images/facade2.png');
    background-position: center 32rem;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
  div.main.container {
    margin-top: 4rem;
    margin-bottom: 6rem;
    max-width: 50rem;
  }
  div.row.cards {
    // margin-bottom: 2rem;
    margin: auto;
    max-width: 1100px;
    .card {
      border: 1px solid #b2bbad;
      border-radius: .5rem;
      margin-bottom: 4rem;
    }
    .card-img-top {
      border-top-right-radius: calc(.5rem - 1px);
      border-top-left-radius: calc(.5rem - 1px);
    }
    .card-block > p {
      @extend .card-text;
      margin-bottom: .75rem;
      & > a { display: none; }
    }
    .btn {
      @extend .custom-button;
      text-transform: capitalize;
      font-weight: 600;
    }
  }
  div.row.cta {
    .cta {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      text-align: center;
      padding: 2rem 3rem;
      p { margin-bottom: 1rem; }
    }
    .cta-donate {
      $cta-donate-bg: #78cccf;
      background: $cta-donate-bg;
      div.custom-button {
        & > a { color: $cta-donate-bg !important; }
      }
    }
    .cta-subscribe {
      $cta-subscribe-bg: #d2ac72;
      background: $cta-subscribe-bg;
      div.custom-button {
        & > a { color: $cta-subscribe-bg !important; }
      }
    }
  }
}

body.search-results,
body.archive,
body.blog {
  h2.entry-title a {
    color: $gray-dark;
    &:hover,
    &:focus,
    &:active {
      color: inherit;
      border-bottom: 4px solid;
    }
  }
}

nav.posts-navigation {
  .nav-links {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    font-weight: 600;
    .nav-next { margin-left: auto; }
    .nav-previous::before {
      content: '\f0d9';
      font-family: "FontAwesome";
      margin-right: .5rem;
      color: $tucker-green;
    }
    .nav-next::after {
      content: '\f0da';
      font-family: "FontAwesome";
      margin-left: .5rem;
      color: $tucker-green;
    }
  }
}
