// Grid settings
$main-md-columns:       12;
$sidebar-md-columns:    4;

// Assets
// $main-logo-path: "../images/logo_wordmark-narrow.svg";

// Colors
$tucker-red:         #821d1d;
$tucker-green:       #3e9316;
$footer-color:       #252f1f;
$tucker-background:  #f8f9fa;

// Fonts
$tucker-body: 'th-MuseoSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$tucker-headings: 'th-CallunaSans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$tucker-interface: 'th-ProximaNova', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// Miscellaneous Bootstrap Overrides
$link-color: $tucker-green;
$link-hover-color: darken($link-color, 10%);
$link-hover-decoration: none;

// // Fonts
$font-family-base: $tucker-interface;
//
// $headings-font-family:   $font-family-sans-serif;
