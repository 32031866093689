article,
.entry-content {
  @include nice_spacing;
  a:hover,
  a:active {
    color: $tucker-green;
    border-bottom: 2px solid;
  }
  ul { font-family: $tucker-body; }
}

article header {
  time,
  p.byline,
  p.tags {
    font-family: $tucker-interface;
    display: inline-block;
  }
  p.tags a {
    @extend .badge;
    @extend .badge-default;
    padding: 3px 4px 2px;
    font-weight: 600;
    font-size: .75rem;
    letter-spacing: .2px;
    vertical-align: text-bottom;
    border-bottom: none !important;
    background-color: lighten($gray-light, 15%);
  }
}

.th-list { display: inline-block; }
.th-list-thumbnail {
  display: inline-block;
  margin: 0 1rem 0 0;
}
.th-post-thumbnail {
  margin: 0;
}
