aside.sidebar {
  height: 100%;
  & > * { margin-top: 1rem; }
  &.sidebar-primary {
    background-color: $tucker-background;
    border-radius: .5rem;
    margin-bottom: 2rem;
    ul { font-family: $tucker-body; }
    @include media-breakpoint-down(xs) {
      border-radius: 0;
      margin-bottom: 0;
    }
  }
  .custom-button {
    width: 100%;
  }
}

.widget {
  font-family: $tucker-interface !important;
  h3 {
    font-family: $tucker-interface;
    font-size: 1.625rem;
    font-weight: 600;
  }
  p { font-family: $tucker-interface; }
}

.widget_search {
  form[role="search"] { justify-content: center; }
}

.widget_facebook-feed { margin-bottom: -1.5rem; }
