// Museo Sans
@font-face {
	font-family: 'th-MuseoSans';
	src: url('../fonts/MuseoSans-500.woff2') format('woff2'), url('../fonts/MuseoSans-500.woff') format('woff'), url('../fonts/MuseoSans-500.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}
@font-face {
	font-family: 'th-MuseoSans';
	src: url('../fonts/MuseoSans-500Italic.woff2') format('woff2'), url('../fonts/MuseoSans-500Italic.woff') format('woff'), url('../fonts/MuseoSans-500Italic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
@font-face {
	font-family: 'th-MuseoSans';
	src: url('../fonts/MuseoSans-900.woff2') format('woff2'), url('../fonts/MuseoSans-900.woff') format('woff'), url('../fonts/MuseoSans-900.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}
@font-face {
	font-family: 'th-MuseoSans';
	src: url('../fonts/MuseoSans-900Italic.woff2') format('woff2'), url('../fonts/MuseoSans-900Italic.woff') format('woff'), url('../fonts/MuseoSans-900Italic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

// Calluna Sans
@font-face {
	font-family: 'th-CallunaSans';
	src: url('../fonts/CallunaSans-Regular.woff2') format('woff2'), url('../fonts/CallunaSans-Regular.woff') format('woff'), url('../fonts/CallunaSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'th-CallunaSans';
	src: url('../fonts/CallunaSans-Italic.woff2') format('woff2'), url('../fonts/CallunaSans-Italic.woff') format('woff'), url('../fonts/CallunaSans-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'th-CallunaSans';
	src: url('../fonts/CallunaSans-Bold.woff2') format('woff2'), url('../fonts/CallunaSans-Bold.woff') format('woff'), url('../fonts/CallunaSans-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'th-CallunaSans';
	src: url('../fonts/CallunaSans-BoldItalic.woff2') format('woff2'), url('../fonts/CallunaSans-BoldItalic.woff') format('woff'), url('../fonts/CallunaSans-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

// Proxima Nova
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-Regular.woff2') format('woff2'), url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-RegularItalic.woff2') format('woff2'), url('../fonts/ProximaNova-RegularItalic.woff') format('woff'), url('../fonts/ProximaNova-RegularItalic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
}
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-SemiBold.woff2') format('woff2'), url('../fonts/ProximaNova-SemiBold.woff') format('woff'), url('../fonts/ProximaNova-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-SemiBoldItalic.woff2') format('woff2'), url('../fonts/ProximaNova-SemiBoldItalic.woff') format('woff'), url('../fonts/ProximaNova-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-Bold.woff2') format('woff2'), url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'th-ProximaNova';
	src: url('../fonts/ProximaNova-BoldItalic.woff2') format('woff2'), url('../fonts/ProximaNova-BoldItalic.woff') format('woff'), url('../fonts/ProximaNova-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}
