header.banner {
  font-family: $tucker-interface;
  font-weight: 600;
  text-transform: uppercase !important;
  letter-spacing: .4px;
  a.navbar-brand {
    position: relative;
    max-width: 15rem;
    @include media-breakpoint-down(xs) { max-width: 12rem; }
    img {
      min-width: 15rem;
      height: 7.5rem;
      @include media-breakpoint-down(xs) {
        min-width: 12rem;
        height: 6rem;
      }
      object-fit: contain;
    }
  }

  #mission {
    position: absolute;
    left: 110%;
    font-style: italic;
    font-weight: 400;
    color: $tucker-red;
    text-transform: none;
    @include media-breakpoint-up(sm) {
      top: 40%;
      font-size: 1.25rem;
    }
    @include media-breakpoint-up(lg) {
      top: 1.25rem;
      font-size: 1.5rem;
    }
    @include media-breakpoint-down(xs) {
      display: none;
    }
  }

  nav.navbar {
    background-color: $tucker-background;
  }

  // Menu layout — this works, don't touch it!!
  #tuckerNav {
    padding-top: 1rem;
    .navbar-nav {
      width: 100%;
      @include media-breakpoint-up(lg) { align-items: center; };
      & > li.menu-item {
        @extend .nav-item;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        & > a {
          @extend .nav-link;
        }
      }
      & > li.menu-item-has-children {
        @extend .dropdown;
        & > a {
          @extend .nav-link;
          @extend .dropdown-toggle;
        }
        & > ul.sub-menu {
          @extend .dropdown-menu;
          @include media-breakpoint-down(md) {
            position: static;
            float: none;
          }
          & > li.menu-item {
            font-weight: 600;
            @extend .dropdown-item;
            a:hover { text-decoration: none; }
          }
        }
      }
    }
  }// That works, don't touch it!!

  .navbar-toggler {
    position: absolute;
    align-self: flex-end;
    margin-top: 2.8rem;
  }

  li.menu-donate,
  li.menu-contact,
  li.menu-faire-un-don,
  li.menu-contacte {
    @extend .tucker-button;
    text-align: center;
  }
  li.menu-donate,
  li.menu-faire-un-don {
    @include button_fill($tucker-green, $white);
    margin-left: auto;
    margin-right: 1rem;
    @include media-breakpoint-down(md) { margin: .75rem 0 1rem; };
  }
  li.menu-contact,
  li.menu-contacte {
    @include button_outline($tucker-green, transparent);
    @include media-breakpoint-down(md) { margin-bottom: .5rem; };
  }

  li.active {
    background-color: $gray-lighter !important;
    &.menu-item-has-children,
    &.menu-donate,
    &.menu-contact { background-color: transparent !important; }
  }
  li:active { background-color: transparent !important; }

  a[data-toggle="dropdown"],
  .dropdown-toggle {
    &::after {
      font-family: "FontAwesome";
      content: "\f0d7";
      display: inline-block;
      width: auto;
      height: .6rem;
      margin-left: .25rem;
      margin-top: -1.2rem;
      vertical-align: middle;
      border-top: none;
      border-right: none;
      border-left: none;
    }
    &[aria-expanded="true"]::after {
      content: "\f0d8";
      margin-top: -1.4rem;
    }
  }

  #langToggle {
    position: absolute;
    top: 1.5rem;
    right: 1.25rem;
    color: $gray-light;
    text-transform: capitalize;
    font-weight: 600;
    font-size: .875rem;
    letter-spacing: 0;
    line-height: 1;
    &:hover,
    &:active,
    &:focus { color: $gray; }
  }
}

div.pageBanner {
  position: relative;
  padding: 0;
  height: 20rem;
  display: flex;
  overflow: hidden;
  @include media-breakpoint-only(sm) { height: 14rem; }
  @include media-breakpoint-down(xs) { height: 10rem; }
  h1 {
    position: absolute;
    width: 100%;
    padding: 0 2rem;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
    font-family: $tucker-headings;
    font-size: 4.5rem;
    @include media-breakpoint-only(sm) { font-size: 3.5rem; }
    @include media-breakpoint-down(xs) { font-size: 2.5rem; }
    text-shadow: 0 8px 5px rgba(0,0,0,.1),
                 8px 5px 5px rgba(0,0,0,.1),
                 -8px 5px 5px rgba(0,0,0,.1);
    color: $white;
  }
  img {
    object-fit: cover;
  }
}
