// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  margin: .125rem;
  @extend .form-group;
  flex-grow: 1;
}
.search-form .search-field {
  @extend .form-control;
  flex-grow: 1;
  font-family: $tucker-interface;
  &:focus { border-color: $tucker-green; }
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-primary;
  margin: .125rem;
  font-family: $tucker-interface;
  background-color: $tucker-green;
  border-color: $tucker-green;
  &:hover, &:active {
    background-color: darken($tucker-green, 10%);
    border-color: darken($tucker-green, 10%);
  }
}

// Constact Contact signup form
form.ctct-custom-form {
  h3 { margin-bottom: 1.5rem; }
  p { margin-bottom: 1rem; }
  label.col-form-label {
    display: flex;
    align-items: center;
  }
  label > span.text-danger { margin-left: .25rem; }
  label, input { font-family: $tucker-body; }
  button {
    padding-top: .75rem !important;
    padding-right: 1.25rem !important;
    padding-bottom: .75rem !important;
    padding-left: 1.25rem !important;
  }
  .ctct-form-errorMessage {
    font-weight: 600;
    @extend .alert;
    @extend .alert-danger;
    order: 3;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
    padding: .5rem 1rem;
    @extend .col-sm-10;
    @extend .offset-sm-1;
    text-align: center;
  }
  .ctct-form-footer {
    font-size: .875rem;
    margin-top: 1.5rem;
    text-align: center;
    color: $gray;
  }
  .form-group.row[data-id='Lists:p'] { margin-bottom: 0; }
}
